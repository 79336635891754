import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: "https://9cb36a1cd60512954197c021cbf0249f@o4507963384791040.ingest.us.sentry.io/4507963392524288",
  environment: process.env.NODE_ENV,
  // Giảm tỷ lệ lấy mẫu trong môi trường production
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 1.0,
  // Thêm các tùy chọn khác để tối ưu hiệu suất
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  normalizeDepth: 5,
  // Bỏ qua một số lỗi không cần thiết
  ignoreErrors: ["Network request failed", "Top-level navigation"],
});
